import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TemplateImage from '../../../screens/Certificate';
import * as htmlToImage from 'html-to-image';
import toast from "../../../services/toast";

import S3 from "../../../services/aws/s3";

import {
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    WhatsappIcon,
    LinkedinIcon,
    TelegramIcon,
    TwitterIcon
  } from "react-share";


function calculatePixel(desiredPixel, pixelRatio) {
    pixelRatio = window.devicePixelRatio;
    // Check if pixel ratio is 1
    if (pixelRatio === 1) {
      return desiredPixel;
    } else {
      // Divide desired pixel by pixel ratio to get actual pixel
      return desiredPixel / pixelRatio;
    }
  }


  const SocialShare = ({url,quote,hashtag})=>{
    return (
        <div style={{display:'flex', justifyContent:'center'}}>
        <FacebookShareButton  openShareDialogOnClick={url !== "none"} url={url} quote={quote} hashtag={hashtag} ><FacebookIcon /></FacebookShareButton>
        <WhatsappShareButton url={url} title={quote}> <WhatsappIcon/> </WhatsappShareButton>
        <TwitterShareButton url={url} ><TwitterIcon/></TwitterShareButton>
        <LinkedinShareButton url={url} ><LinkedinIcon/> </LinkedinShareButton>      
        <TelegramShareButton url={url} ><TelegramIcon/></TelegramShareButton>
        </div>
    )
  }
  const isSafariOrIPhoneChrome = () => {
    const userAgent = navigator.userAgent;
    const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
    const isIPhoneChrome = /iPhone.*Chrome/.test(userAgent);
    return isSafari || isIPhoneChrome;
  };
export default function SelectImage({campaignData,title,isOpen, image,changeImage, templateData,handleClick,cancelFn,bidNumber}) {
    const [open, setOpen] = React.useState(isOpen);
    React.useEffect(()=>{
        setOpen(isOpen);
        // handleClick(isOpen);
       
    },[isOpen]);

   
    const [configData, setConfigData] = React.useState(templateData);
    const [certificate, setCertificate] = React.useState(null);
    const [imageWidth, setImageWidth] = React.useState(null);
    const [imageHeight, setImageHeight] = React.useState(null);
    const [isOpenShare, setIsOpenShare] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [isDownloaded, setIsDownloaded] = React.useState(false);

    const [isDownloading, setIsDownloading] = React.useState(false);


    React.useEffect(()=>{
      setConfigData(templateData);
      setIsDownloading(false);
      setIsDownloaded(false);
    },[templateData]);


    React.useEffect(()=>{
        if(certificate){
            setIsOpenShare(true);
        }
    },[certificate])
  const createImage = async () =>{
    setIsLoading(true);    
    setIsError(false); 
    const filter = (node) => {
      const exclusionClasses = ['Buttons','no-print'];
      return !exclusionClasses.some((classname) => node.classList?.contains(classname));
    }
    let options = {
      canvasHeight:calculatePixel(imageHeight),
      canvasWidth:calculatePixel(imageWidth),
      filter,
      pixelRatio:1,
    };

    if (isSafariOrIPhoneChrome()) {
      let dataUrl =  await safariDownload(options);

      let timestamp = + new Date();     
      S3.uploadCertificate(dataUrl,timestamp+'certificate.jpeg',(data)=>{
          setIsLoading(false);
            setCertificate(data.Location);
        })
    
     }else{

    htmlToImage.toJpeg(document.getElementById('certificate'), options)
    .then(function (dataUrl) {
      let timestamp = + new Date();     
      S3.uploadCertificate(dataUrl,timestamp+'certificate.jpeg',(data)=>{
          setIsLoading(false);
            setCertificate(data.Location);
        })
    }).catch(err=>{
      setIsLoading(false);
      setIsError('Something went wrong. Please try again later');
    });
  }
  }


  const safariDownload = async(options)=>{
    const element = document.getElementById('certificate');
    let dataUrl = '';
    const minDataLength = 2000000;
    let i = 0;
    const maxAttempts = 10;

    while (dataUrl.length < minDataLength && i < maxAttempts) {
      dataUrl = await htmlToImage.toPng(element, options);
      i += 1;
    }
    return dataUrl;
  }

  const Download = async () => {
    console.log('Platform ', isSafariOrIPhoneChrome());
    setIsDownloading(true);
    setIsDownloaded(false);
  
    const filter = (node) => {
      const exclusionClasses = ['Buttons', 'no-print'];
      return !exclusionClasses.some((classname) => node.classList?.contains(classname));
    };
  
    const options = {
      canvasHeight: calculatePixel(imageHeight),
      canvasWidth: calculatePixel(imageWidth),
      filter,
    };
  
    try {
      if (isSafariOrIPhoneChrome()) {
        // Safari or iPhone Chrome specific handling
        const dataUrl = await safariDownload(options);
        if (!dataUrl) {
          toast.error('Failed to generate the image. Please try again.');
          return;
        }
        const link = document.createElement('a');
        link.download = 'certificate.png';
        link.href = dataUrl;
        link.click();
        toast.show('Image saved successfully. Go to your downloads directory');
        setIsDownloading(false);
        setIsDownloaded(true);

      } else {
        const dataUrl = await htmlToImage.toJpeg(document.getElementById('certificate'), options);
        if (!dataUrl) {
          toast.error('Failed to generate the image. Please try again.');
          return;
        }
        const link = document.createElement('a');
        link.download = 'certificate.jpeg';
        link.href = dataUrl;
        link.click();
        toast.show('Image saved successfully. Go to your downloads directory');
        setIsDownloaded(true);
        setIsDownloading(false);

      }
    } catch (error) {
      console.error('Image download failed:', error);
      toast.error('Oops! The image could not be downloaded. Please try again.');
      setIsDownloading(false);

    }
  };
  
  return (
    <div>    
      <Dialog
        fullWidth
        open={open}
        disableEscapeKeyDown={false}
        disableBackdropClick={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >     
        <DialogTitle id="alert-dialog-title">
         <h6>{title}</h6>
        </DialogTitle>
        <DialogContent>
          {(configData) &&         
            <TemplateImage bidNumber={bidNumber} configData={configData} image={image} onChangeHeight={(height) => setImageHeight(height)} onChangeWidth={(width) => setImageWidth(width)}/> 
            || <p>Please wait. Loading Designer view...</p>
          }
        </DialogContent>
        
        {
            !certificate &&
            <DialogActions>
              {campaignData?.is_image_certificate &&
                        <Button onClick={changeImage}>Change Image</Button>
                || 
                <Button onClick={cancelFn}>Cancel</Button>
              }
        
        <Button onClick={createImage} autoFocus>
            {isLoading ? 'Generating...'  : 'Done'}
          </Button>                  
        </DialogActions>
        }
        
        {
            certificate &&
            <>
            <SocialShare 
            url={certificate} 
            quote={campaignData?.description}
            // hashtag={'#bmm #bangalore #marathon'}
            />
          <Button onClick={Download}>{isDownloaded ? 'Re-Download' :(isDownloading ? 'Downloading...' : 'Download') }</Button>
          <Button onClick={()=>{setOpen(false);handleClick(false,true);setCertificate(null)}}>Close</Button>
            </>
        }
        {
          isError &&   <DialogActions><p > {isError}</p></DialogActions>
        }
        
      </Dialog>
    </div>
  );
}