import React, { useState, useEffect } from 'react';
import PreviewImage from './preview'
import DraggableText from './text'
import StorageService from "../../services/StorageService";

import './style.css';


const  getSizeForWidth = (width, sizeChart)=> {
  if(typeof sizeChart != 'object') return `calc(${sizeChart}px + 1vw)`;

  // Convert the width to a number to ensure proper comparison
  width = parseInt(width);

  // Initialize a variable to store the size
  let selectedSize = null;

  // Iterate through the keys of the size chart object
  Object.keys(sizeChart).forEach(key => {
      // Convert the key (width) to a number
      const chartWidth = parseInt(key);

      // Check if the given width is less than or equal to the current chart width
      if (width <= chartWidth) {
          // If the size hasn't been assigned yet or the current chart width is smaller, update the selected size
          if (!selectedSize || chartWidth < parseInt(Object.keys(sizeChart)[0])) {
              selectedSize = sizeChart[key];
          }
      }
  });
  console.log(selectedSize,'size');
  // Return the selected size
  return selectedSize;
}
const isWindowsLaptop = /Windows/.test(navigator.userAgent) && !/Phone|Tablet|Mobile/.test(navigator.userAgent);


function excelTimeToHMS(excelTime) {
  // Check if excelTime is a number and if it has a decimal part (i.e., it's a float)
  if (typeof excelTime !== 'number' || !Number.isFinite(excelTime) || Math.floor(excelTime) === excelTime) {
    return excelTime;  // Return the original value if it's not a float number
  }

  // Calculate total seconds in the given fraction of a day
  let totalSeconds = Math.round(excelTime * 24 * 60 * 60);

  // Extract hours
  let hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;

  // Extract minutes
  let minutes = Math.floor(totalSeconds / 60);

  // Extract remaining seconds
  let seconds = totalSeconds % 60;

  // Pad hours, minutes, and seconds with leading zero if needed
  hours = String(hours).padStart(2, '0');
  minutes = String(minutes).padStart(2, '0');
  seconds = String(seconds).padStart(2, '0');

  // Return in HH:MM:SS format
  return `${hours}:${minutes}:${seconds}`;
}


function calculateProportionalDimensions(originalWidth, originalHeight, originalBoxWidth, originalBoxHeight, newWidth) {
    // Calculate the width ratio
    const widthRatio = newWidth / originalWidth;
  
    // Calculate the new box width based on the width ratio
    const newBoxWidth = originalBoxWidth * widthRatio;
  
    // Calculate the height ratio based on the smaller dimension to preserve aspect ratio
    const heightRatio = Math.min(newWidth / originalWidth, newBoxWidth / originalBoxWidth);
  
    // Calculate the new box height based on the height ratio
    const newBoxHeight = originalBoxHeight * heightRatio;
  
    return { width:newBoxWidth,height:newBoxHeight };
  }


  

const TemplateImage = ({image, onChangeHeight, onChangeWidth, configData,bidNumber}) => {
  const templateUrl = configData.baseUrl;
  const [bid,setBid] = useState(bidNumber);
  const [imageSrc, setImageSrc] = useState(image);
  const [isLoading,setIsLoading] = useState(true);
  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);
  const [renderImageHeight, setRenderImageHeight] = useState('100%');
  const [renderImageWidth, setRenderImageWidth] = useState('100%');

  const [userDetails,setUserDetails] = useState(JSON.parse(StorageService.getData("user_details")));

  const [campaignData,setCampaignData] = useState(JSON.parse(StorageService.getData("campaign_data")));

  const [renderImageResolution,setRenderImageResolution] = useState(configData.image_resolution);

  const [nameSize,setNameSize] = useState(configData.name_axis?.size ? configData.name_axis?.size : 10);
  const [timeSize,setTimeSize] = useState(configData.time_axis?.size ? configData.time_axis?.size : 12);


  const [removeTimeResolution,setRemoveTimeResolution] = useState({width:336,height:88});
  
  const [renderAxisImage, setRenderAxisImage] = useState(configData.image_axis); 

  const [renderAxisName, setRenderAxisName] = useState(configData.name_axis);
  const [renderAxisTime, setRenderAxisTime] = useState(configData.time_axis);
  const [removeAxisTime, setRemoveAxisTime] = useState({x:758,y:642});

  // const [renderBibTime, setRenderBibTime] = useState(configData?.options?.BIB);
  // const [renderCategory, setRenderCategory] = useState(configData?.options?.category);
  // const [renderCategoryRank, setRenderCategoryRank] = useState(configData?.options?.category_rank);

  const [originalRes, setOriginalRes] = useState(null);
  const [renderedRes, setRenderedRes] = useState(null);
  //Specific for Event
  const [renderTimeContentResolution,setRenderTimeContentResolution] = useState({width:1749,height:204});
  const [text1,setText1] = useState({width:650,height:100});
  const [text2,setText2] = useState({width:700,height:100});
  const [timing,setTiming] = useState({width:380,height:100});

  const [text3,setText3] = useState({width:955,height:115});


  function calculateRenderedCoordinates(originalResolution, renderedResolution, referencePoint) {
    const originalX = originalResolution.x;
    const originalY = originalResolution.y;

    const renderedX = renderedResolution.x;
    const renderedY = renderedResolution.y;

    const referenceX = referencePoint.x;
    const referenceY = referencePoint.y;

    // Calculate the ratio
    const xRatio = renderedX / originalX;
    const yRatio = renderedY / originalY;

    // Calculate the corresponding coordinates
    let renderedXCoordinate = Math.round(referenceX * xRatio);
    const renderedYCoordinate = Math.round(referenceY * yRatio);
    
  
    return { x: renderedXCoordinate, y: renderedYCoordinate };
}

  useEffect(()=>{
    console.log(bidNumber);
    setBid(bidNumber);
  },[
    bidNumber
  ])


const TDimension = (event) =>{
  let target = event.target;
  onChangeHeight(event.target.naturalHeight);
  onChangeWidth(event.target.naturalWidth);

  setImageHeight(event.target.naturalHeight);
  setImageWidth(event.target.naturalWidth);
  setRenderImageHeight(target.height -2);
  setRenderImageWidth(target.width - 2);
    let originalResolution = {x:target.naturalHeight,y:target.naturalWidth};
    setOriginalRes(originalResolution);
    let renderedResolution = {x:target.height,y:target.width};
    setRenderedRes(renderedResolution);
    // Calculate Image Render Axis
    setRenderAxisImage(calculateRenderedCoordinates(originalResolution,renderedResolution, renderAxisImage));
  
    //Calculate Image Render Resolution
    setRenderImageResolution(calculateProportionalDimensions(target.naturalWidth, target.naturalHeight, renderImageResolution.width, renderImageResolution.height, target.clientWidth ));
    setRemoveTimeResolution(calculateProportionalDimensions(target.naturalWidth, target.naturalHeight, removeTimeResolution.width, removeTimeResolution.height, target.clientWidth ));
    setRenderTimeContentResolution(calculateProportionalDimensions(target.naturalWidth, target.naturalHeight, renderTimeContentResolution.width, renderTimeContentResolution.height, target.clientWidth ));

    //Event Specific
    setText1(calculateProportionalDimensions(target.naturalWidth, target.naturalHeight, text1.width, text1.height, target.clientWidth ));
    setText2(calculateProportionalDimensions(target.naturalWidth, target.naturalHeight, text2.width, text2.height, target.clientWidth ));
    setText3(calculateProportionalDimensions(target.naturalWidth, target.naturalHeight, text3.width, text3.height, target.clientWidth ));
    setTiming(calculateProportionalDimensions(target.naturalWidth, target.naturalHeight, timing.width, timing.height, target.clientWidth ));

    //Calculate Name Render Axis
    setRenderAxisName(calculateRenderedCoordinates(originalResolution,renderedResolution, renderAxisName));
    setRemoveAxisTime(calculateRenderedCoordinates(originalResolution,renderedResolution, removeAxisTime));

    
     //Calculate Time Render Axis
     setRenderAxisTime(calculateRenderedCoordinates(originalResolution,renderedResolution, renderAxisTime));
    //  setRenderBibTime(calculateRenderedCoordinates(originalResolution,renderedResolution, renderBibTime));
    //  setRenderCategory(calculateRenderedCoordinates(originalResolution,renderedResolution, renderCategory));
    //  setRenderCategoryRank(calculateRenderedCoordinates(originalResolution,renderedResolution, renderCategoryRank));

    setIsLoading(false);
}
const { innerWidth: width, innerHeight: height } = window;
const textFont = getSizeForWidth(width, timeSize);

  return (
    // <div className="pageOuter">
    // <div className="headerGreenBlock">
    //   <div className="headerOuter">
    <>
        {/* <button onClick={Download}>Download</button> */}
            {/* <input type="file"  accept="image/*" onChange={handleImageUpload} /> 
            replace('https://dev.api.eventforce.ai/','serverimage/')
            */}
            {
              configData.baseUrl &&
            
        <div style={{position:'relative',height:renderImageHeight, width:renderImageWidth}} id="certificate">
            <img src={templateUrl} id="myImage" className='imagetemplate' onLoad={TDimension} alt="Template Image" />          
            {
              !isLoading && <>
                <DraggableText disableDrag={configData.disable_name_drag} color={configData?.color} size={nameSize} campaignData={campaignData} text={configData.name ? configData.name : userDetails.name} cordinates={renderAxisName}/>
                {
                  configData.time && (
                    campaignData?.image_console_id ==140 &&
                    ( <div style={{width:renderTimeContentResolution.width,color:'#595b61',position:'absolute',left:renderAxisTime.x, top:renderAxisTime.y, zIndex:999, fontSize:9}}>
                      <div style={isWindowsLaptop ? {display:'flex', alignItems:'center'} : {display:'flex'}}>
                      {/* <p style={{width:text1.width, fontSize:`calc(${textFont} - 3px)` }}>finishing in a time of</p> */}
                      <img  style={{width:text1.width,height:text1.height,objectFit:'contain'}} 
                       src='images/text-1.png' />
                      <DraggableText isBetween={true}  renderResolution={timing} size={timeSize} color={configData?.color}  disableDrag={configData.disable_time_drag} text={configData.time} cordinates={renderAxisTime}/>
                      {/* <p  style={{width:text2.width, fontSize:`calc(${textFont} - 4px)`}}>On 14th December 2024</p> */}
                      <img  style={{width:text2.width,height:text2.height,objectFit:'contain'}}
                       src='images/png03.png' />
                      </div>
                      <img  style={{width:text3.width,height:text3.height,objectFit:'contain',float:'right'}}
                       src='images/png04.png' />
                      </div>) ||
                      <DraggableText size={timeSize} color={configData?.color}  disableDrag={configData.disable_time_drag} text={configData.time} cordinates={renderAxisTime}/>

                  )

                 
                ||
                null
                // <DraggableText  disableDrag={true} disabled={true} cordinates={removeAxisTime} removeTimeResolution={removeTimeResolution}/>
                }
            {console.log('Font Size',timeSize)}
            {
              (originalRes && renderedRes && configData?.options) &&
              Object.keys(configData.options).map(key => {
                const item = configData.options[key];
                if(configData?.data[key]){
                  return <DraggableText color={configData?.color} size={item?.size}  disableDrag={true} text={excelTimeToHMS(configData?.data[key])} cordinates={calculateRenderedCoordinates(originalRes,renderedRes, item)}/>
                }
                console.log('Console Data', configData?.data[key],key);
                // Perform any other operations with the item here
              })
            }


                {/* {
                 configData?.options?.length > 0 && 
                 configData?.options?.map(item=>console.log('Console Data',item))

                } */}
                 {(imageSrc && imageSrc!='no-image') && <PreviewImage x={renderAxisImage.x} y={renderAxisImage.y} width={renderImageResolution?.width} height={renderImageResolution?.height} imageSrc={imageSrc} />}
              </>
            }
          
        </div> ||
        <p>Data not found for selected BIB. Please contact with organizer.</p>
        }
        </>
    // </div>    
    // </div>
    // </div>

  );
};

export default TemplateImage;