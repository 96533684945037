import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Generate from './generate'
import ApiService from "../../../services/ApiService";
import awsS3 from '../../../services/aws/s3';
import axios from 'axios'

async function imageUrlToBlob(imageUrl) {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error('Error converting image URL to Blob:', error);
    return null;
  }
}


export default function SelectImage({setLoading,imageLoaded, certificateCategory,campaignData,title,children,isOpen,handleClick,selectedCertificate,bidNumber,frEventId,type, campaignId,isCertificate,cancelFn,showCertificateApp}) {
  const [open, setOpen] = React.useState(isOpen);
  const [image, setImage] = React.useState(false);
  const [imageSelected, setImageSelected] = React.useState({});
  const fileInputRef = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [bib, setBib] = React.useState(bidNumber);
  const [eventId, setEventId] = React.useState(frEventId);
  const [imageType, setImageType] = React.useState(type);
  const [templateData, setTemplateData] = React.useState({});
  const [showCertificate, setShowCertificate] = React.useState(showCertificateApp);

  const [isOpenGenerate, setIsOpenGenerate] = React.useState(false);
React.useEffect(()=>{
  if(!showCertificateApp){
    setImage(false);
  }else {
    console.log(templateData?.baseUrl,showCertificate)
    if(templateData?.baseUrl){
      setImage('no-image');
      setIsOpenGenerate(true);
    }
  }
 
  setShowCertificate(showCertificate);
},[showCertificateApp, templateData])
  React.useEffect(()=>{
    setLoading(true);
    let bib = certificateCategory ? certificateCategory : bidNumber;
    if(bidNumber){
      try{
     ApiService.getData(`getCertificateTemplate`, {campaign_id:campaignId,bib_number:bib},true).then((response) => {
      if(response.status == 'success') {
        // setIsLoading(false);
        let {data} = response;
      
        if(certificateCategory)
        data.name = bidNumber;

       configureTemplate(data);
        setIsLoading(false);
        console.log("This Call ",showCertificateApp,data);
        imageLoaded();
    setLoading(false);
        if(showCertificateApp){
          
          setImage('no-image');
           setIsOpenGenerate(true);

        }
        
      }else{
        setLoading(true);

      }
     }).catch((error)=>{
      setIsLoading(false);
      console.error('Error getting certificate template:', error);
     }).finally(()=>{
      setIsLoading(false);

     })
    }catch(error) {
      setLoading(true);
    }
   }
 },[bidNumber,certificateCategory]);



  const configureTemplate = async (data) =>{
    
    data.baseUrl = await getImageAsDataURL(data.template);
    setTemplateData(data);
  } 


  React.useEffect(()=>{
    setBib(bidNumber);
    setEventId(frEventId);
    setImageType(type);
  },[bidNumber,frEventId,type]);

  React.useEffect(()=>{
    setOpen(isOpen);
    handleClick(isOpen);
  },[isOpen]);

  React.useEffect(()=>{
    // console.log(selectedCertificate?.image);
    setImageSelected(selectedCertificate?.image);    
  },[selectedCertificate]);

  React.useEffect(()=>{
    if(image) {
      setOpen(false);
      handleClick(false);
      setIsOpenGenerate(true);
    }
   
  },[image])

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => setImage(e.target.result);
    reader.readAsDataURL(file);
  };

  async function getImageAsDataURL(url) {
    try {
      const response = await axios.get(url, { responseType: 'blob' });
  
      const reader = new FileReader();
      reader.readAsDataURL(response.data);
  
      await new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
  
      const dataURL = reader.result;
      return dataURL.replace('binary/octet-stream','image/jpeg');
    } catch (error) {
      console.error('Error fetching or converting image:', error);
      throw error; // Re-throw to allow error handling at a higher level
    }
  }

  
  const handleOkay = async ()=>{
    // setImage(null);
    setIsLoading(true);
    // let image = await imageUrlToBlob(selectedCertificate.image.replace('https://dtq9cqmh745pp.cloudfront.net/','/cloudfront/'));
    // console.log('Blob Image',image);
    // setImage(selectedCertificate.image);     
    if(selectedCertificate?.id) {
    let imageID = selectedCertificate.id;
        let params  = 'image_id=['+imageID+']&event_id='+eventId+'&type='+imageType;
        if(bib){
          params += `&bib=${bib.toUpperCase()}`; 
        }
          ApiService.imgConsoleGetData('images/search/cloud-urls?'+params).then(response=>{
            let originalImages = response[0].matches;
            let imageLink = originalImages[0].image;
            // console.log(imageLink.replace('https://dtq9cqmh745pp.cloudfront.net/','cloudfront/')); return;
              getImageAsDataURL(imageLink)
              .then(dataURL => {
                setIsLoading(false);
                setImage(dataURL);

                // console.log('Image converted to Data URL:', dataURL);
              })
              .catch(error => {
                setIsLoading(false);
                alert('Something went wrong. Please try again later.');
                console.error('Failed to get image:', error);
                // Handle error gracefully, e.g., display an error message to the user
              });
           
            // setImage(originalImages[0].image);             
          })
    }
   
  }

  const handleChooseFromDevice = () => {
    // Trigger click on the file input
    fileInputRef.current.click();
  };
  return (
    <div>    
      <Generate 
      campaignData={campaignData}
      image={image} isOpen={isOpenGenerate} 
      templateData={templateData}
      bidNumber={bib}
      cancelFn={()=>{
        cancelFn();
        setIsOpenGenerate(false);
      }}
      handleClick={(value, isClear)=>{handleClick(value,isClear); setImage(null);setImageSelected(false);setIsOpenGenerate(false);}}
      changeImage={()=>{
        setImage(null);
        setImageSelected(false);
        setIsOpenGenerate(false);
        setOpen(true);
      }}/>
      {/* {`${campaignData?.is_image_certificate} dfs`} */}
     {campaignData?.is_image_certificate!=0 && (
  <Dialog
    disableEscapeKeyDown={false}
    disableBackdropClick={true}
    fullWidth
    open={open}
    onBackdropClick={() => setOpen(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      <h6>{title}</h6>
    </DialogTitle>
    <DialogContent>
      <Button onClick={handleChooseFromDevice}>Choose from device</Button>
      <div style={{ display: 'none' }}>
        <input ref={fileInputRef} type="file" accept="image/*" onChange={handleImageUpload} />
      </div>
      {children}
    </DialogContent>
    <DialogActions>
      <Button onClick={() => { setOpen(false); handleClick(false, true) }}>Cancel</Button>
      {
        imageSelected &&
        <Button onClick={handleOkay} autoFocus>
          {isLoading ? 'Please wait...' : 'Proceed'}
        </Button>
      }
    </DialogActions>
  </Dialog>
)}

    </div>
  );
}